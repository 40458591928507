.ci {
  vertical-align: center;
}

.secondary-card-icon {
  color: black;
  font-size: 2.6rem;
  text-align: center;
}
.secondary-card-icon-active {
  color: #fff !important;
}

.count-icon {
  font-size: 2.6rem;
}
