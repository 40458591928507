.contact-form-container {
  background-image: url("../../../assets/images/contact.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50%;
  position: relative;
}

/* .contact-form-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0021474b;
  z-index: 1;
} */
