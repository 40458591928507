.list-disk {
  width: 16px;
  height: 16px;
  border: 3px solid black;
  border-radius: 50%;
}

.list-container:hover .list-disk {
  border-color: #ff683f;
}
.list-container:hover .list {
  color: white;
}
