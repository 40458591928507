.slide {
  opacity: 0;
  transition: opacity 0.5s ease-in;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: contain;
}
.slide1 {
  /* background-image: url("../../../assets/images/slider1.webp"); */
  background-image: url("../../../assets/images/students-graduation.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.slide2 {
  background-image: url("../../../assets/images/slider2.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.slide3 {
  /* background-image: url("../../../assets/images/slider3.jpg"); */
  background-image: url("../../../assets/images/medium-shot-low-angle-view-engineer-architect-supervising-construction.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.slide.active {
  opacity: 1;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #333;
}

.slick-dots li.slick-active button:before {
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(14, 28, 45, 0.75);
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* transition: opacity 0.3s ease-in-out; */
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  width: 100%;
}
