.layout {
  position: relative;
  height: 300px;
  background-image: url("../../../assets/images/nhp-common-img.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
/* .layout.about-us {
  background-image: url("");
}
.layout.our-service {
  background-image: url("");
} */
.layout.mission {
  background-image: url("../../../assets/images/mission-vision-banner.jpg");
}
.layout.privacy {
  background-image: url("../../../assets/images/privacy-banner.jpg");
}
.layout.ceo-message {
  background-image: url("../../../assets/images/message-ceo-banner.jpg");
}
/* .layout.study-us {
  background-image: url("");
}
.layout.study-uk {
  background-image: url("");
}
.layout.study-canada {
  background-image: url("");
}
.layout.study-australia {
  background-image: url("");
}
.layout.scholarship {
  background-image: url("");
} */

.main-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  grid-gap: 3rem;
}

@media only screen and (min-width: 1024px) {
  .main-content {
    grid-template-columns: 1fr 3fr;
  }
}
.layout-header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(71, 103, 135, 0.7);
  height: 100%;
}

.layout-header-container h2::after {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 38%;
  height: 4px;
  border-radius: 8px;
  width: 90px;
  background-color: #ff683f;
}
