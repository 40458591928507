/* .sticky {
  background-color: #2a2a72;
  background-image: linear-gradient(
    315deg,
    rgb(42, 42, 114, 0.7) 0%,
    rgb(0, 159, 253, 0.7) 74%
  );
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  transition: all 0.3s ease-in-out;
} */

.ani-nav-link {
  position: relative;
}
.ani-nav-drop {
  position: relative;
}
.ani-nav-link a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #ff683f;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.ani-nav-drop button:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: #ff683f;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}

.ani-nav-link a:focus:before,
.ani-nav-link a:hover:before,
.ani-nav-drop button:focus:before,
.ani-nav-drop button:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
