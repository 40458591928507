.about-us-container {
  background-image: url("../../../assets/images/hwwrd.webp");
  background-position: right;
  background-repeat: no-repeat;
}

.CircularProgressbar-trail {
  stroke-width: 5px;
}
.CircularProgressbar-path {
  stroke-width: 5px;
}
