.circle-inactive {
  position: absolute;
  left: -22px;
  top: 8px;
  height: 15px;
  width: 15px;
  border: 3px solid #002147;
  border-radius: 50%;
}
.circle {
  position: absolute;
  left: -22px;
  top: 8px;
  height: 15px;
  width: 15px;
  border: 3px solid #ff683f;
  border-radius: 50%;
}
.accrodian-body-span {
  color: #0099ff;
  margin: 1rem 0;
  font-size: 18px;
}

.collapse-list li::after {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  top: 7px;
  left: -25px;
  background-color: #ff683f;
  transform: rotate(43deg);
}
